.tableSportsTitle {
     color: var(--white);
     background-color: var(--purple);
     margin: 24px 0px;
     font-weight: bold;
     margin-left: -12px;
     padding: 8px;
     border-radius: 8px;
}

.gameTableContainer {
     height: 50vh;
     border-radius: 16px;
     overflow-y: auto;
     padding: 16px 0px;
     background-color: var(--white);
}

.gameTableContainer::-webkit-scrollbar {
     display: none;
}

.tableHead {
     color: var(--lightGreen);
}

.tableRow {
     color: var(--gray);
}

.tableRow td {
     padding: 16px 8px;
}

.tableTimeCell {
     font-size: 14px;
     font-weight: bold;
     background-color: var(--pink) !important;
}

.tableCell {
     font-size: 14px !important;
     font-weight: bold !important;
}

.tableCell p {
     margin: 0 !important;
}