.betTypesWrapper {
    background-color: var(--white);
    border-radius: 8px;
    padding: 0;
    margin-bottom: 32px;
}

.betTypesTitle {
    background-color: var(--purple);
    color: var(--white);
    padding: 8px;
    border-radius: 8px 8px 0px 0px;
}

.betTypesContainer {
    padding: 8px;
}

.betTypes {
    color: var(--lightGreen);
    margin-bottom: 4px;
    cursor: pointer;
}

.betTypeContainer {
    display: flex;
    width: 100%;
}

.actionLinksContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.betTypeText {
    margin-top: 4px;
    font-weight: bold;
}