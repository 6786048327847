.newsTitle {
    border-radius: 8px;
    padding: 8px;
    background-color: var(--purple);
    color: var(--white);
}

.newsContainer {
    padding: 16px;
    margin-bottom: 8px;
    border-bottom: 1px solid var(--lightGray);
    display: flex;
}

.newsBody {
    margin-left: 16px;
    width: 100%;
}

.newsBodyTitle {
    font-weight: bold;
    color: var(--gray);
}

.newsBodyDateButton {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.newsDate {
    font-size: 12px;
    font-weight: bold;
    margin: 0;
}

.newsReadMoreButton,
.newsReadMoreButton:hover,
.newsReadMoreButton:focus {
    background-color: var(--orange);
    border: none;
    border-radius: 8px !important;

}

.newsReadMoreButton a,
.newsReadMoreButton:hover a,
.newsReadMoreButton:focus a {
    color: var(--white) !important;
    text-decoration: none !important;
}