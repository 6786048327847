.profileFieldsWrapper {
    padding: 32px 0px;
}

.inputLabel {
    color: var(--gray);
    font-size: 16px;
}

.input,
.input:focus {
    border-radius: 0px;
    background-color: var(--offWhite);
}

.editSubmitButton {
    width: 100% !important;
    background-color: var(--purple) !important;
    border: none !important;
    padding: 12px !important;
    border-radius: 8px !important;
    font-weight: bold !important;
    color: var(--white) !important;
}

.profileSentence {
    color: var(--purple);
    font-weight: bold;
}