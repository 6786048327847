.footerWrapper {
    background-color: var(--lightGreen);
    height: auto;
}

.linksContainer {
    color: var(--white);
    margin-top: 32px;
    margin-left: 16px;
    font-weight: 600;
}

.socialMediaWrapper {
    text-align: left;
}

.socialMediaContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.copyRight {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    background-color: var(--lightGreen);
    height: 40px;
    font-weight: 600;

}

.footerText {
    font-size: 14px;
    text-align: left;
    margin-top: 8px
}

.SocialMediaIcons {
    margin-right: 16px;
    cursor: pointer
}

@media screen and (max-width:767.5px) {
    .socialMediaWrapper {
        text-align: center !important;
    }

    .footerText {
        text-align: center;
        margin-top: 16px;
    }
}

.mainLinksContainer {
    padding: 16px 0px;
    display: flex;
    color: var(--white);
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
}

.mainLinksContainer p {
    font-weight: bold;
    border-bottom: 2px solid var(--white);
    cursor: pointer;
    font-size: 18px;
}

.leagueLinksContainer {
    padding: 16px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    width: 100%;
}

.leagueLinksContainer p {
    margin: 8px;
    font-size: 14px;
    opacity: 0.8;
    cursor: pointer;
}