.usaLeagueWrapper {
    background-color: var(--white);
    border-radius: 8px;
    padding: 0;
    margin-bottom: 32px;
}

.usaLeagueTitle {
    background-color: var(--purple);
    color: var(--white);
    padding: 8px;
    border-radius: 8px 8px 0px 0px;
}

.usaLeaguesContainer {
    padding: 8px;
}

.usaLeague {
    color: var(--lightGreen);
    margin-bottom: 4px;
    cursor: pointer;
}

.usaLeagueContainer {
    display: flex;
    width: 100%;
}

.usaLeagueText {
    margin-top: 4px;
    font-weight: bold;
    margin-left: 16px;
}