.depositeWrapper {
    width: 100% !important;
}

.depositeContainer {
    padding: 16px;
}

.depositeTitle {
    font-size: 16px;
}

.depositeAmountContainer p {
    opacity: 0.5;
    font-size: 14px;
    font-weight: 600;
}

.inputDisabled {
    height: 40px;
    width: 100%;
    font-size: 18px;
    padding-left: 8px;
    color: black;
}

.copyButton {
    margin-top: 16px !important;
    width: 100% !important;
    border-radius: 8px !important;
    border: none !important;
    background-color: var(--purple) !important;
    color: var(--white);
    font-weight: bold;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}