:root {
    --lightGreen: #2B2C2D;
    --orange: #E79C25;
    --pink: #FFECCC;
    --purple: #4A1B36;
    --black: #000;
    --white: #fff;
    --offWhite: #EDEEF0;
    --gray: #45484D;
    --lightGray: #75ABAD;
    --cyan: #8CF4FF;
    --blue: #2F4858;
    --lightGray: #c4c4c4
}

@font-face {
    font-family: PTSans;
    src: url(./assets/fonts/PTSans-Regular.ttf);
}

* {
    margin: 0;
    padding: 0;
    font-family: 'PTSans';
}


body {
    overflow-x: hidden;
    background-color: var(--offWhite);
}

body::-webkit-scrollbar {
    display: none;
}

.ButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: -24px;
}

.sidBarWrapper {
    margin-top: 32px;
    padding: 0px 32px;
}