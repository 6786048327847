.contactUsWrapper {
    padding: 16px;
}

.contactButton,
.contactButton:hover,
.contactButton:focus {
    width: 100%;
    background-color: var(--purple);
    color: var(--white);
    font-weight: bold;
    border-radius: 8px;
    border: none;
}

.contactUsText {
    color: var(--purple);
    font-weight: bold;
}